<template>
  <div class="setting-main-wrapper">
    <div class="shop-side-auth">
      <div class="privacy-policy-page mb-3">
        <div class="back-to-login-page">
          <router-link to="/login">Back to Login</router-link>
        </div>
        <PolicyTop />
        <Definitions />
        <CollectingInformation />
      </div>
    </div>
  </div>
</template>

<script>
import PolicyTop from "../components/PrivacyPolicy/PolicyTop.vue";
import Definitions from "../components/PrivacyPolicy/Definitions.vue";
import CollectingInformation from "../components/PrivacyPolicy/CollectingInformation.vue";

export default {
  components: {
    PolicyTop,
    Definitions,
    CollectingInformation,
  },
  name: "OurPrivacyPolicy",
};
</script>
