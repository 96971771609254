var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth-wrapper overflow-hidden"},[_c('div',{staticClass:"container-fluid no-pdd"},[_c('div',{staticClass:"row align-items-center no-gutters"},[_c('div',{staticClass:"col-lg-6 d-none d-lg-block no-pdd"},[_c('Left')],1),_c('div',{staticClass:"col-lg-6 no-pdd"},[_c('div',{staticClass:"auth-side"},[_c('AuthTop'),_c('div',{staticClass:"sign-in-form"},[_c('form',{attrs:{"id":"form-wrapper"},on:{"submit":function($event){$event.preventDefault();},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.Login(0)}}},[_c('h4',[_vm._v("Welcome Back")]),(_vm.errorMessage != null)?_c('p',{staticClass:"tag-line",staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_c('p',{staticClass:"tag-line"},[_vm._v(" Enter email and password to login. ")]),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"input-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.email.$model),expression:"$v.details.email.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                      'is-invalid': _vm.$v.details.email.$error,
                      'is-valid': !_vm.$v.details.email.$invalid,
                    },attrs:{"id":"email","spellcheck":"false","autocomplete":"off","trim":"","type":"text","required":""},domProps:{"value":(_vm.$v.details.email.$model)},on:{"keyup":_vm.errorNull,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.email, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"placeholder"},[_vm._v("Email")]),(!_vm.$v.details.email.required)?_c('div',{staticClass:"invalid-tooltip"},[_vm._v(" Email is required ")]):_vm._e(),(!_vm.$v.details.email.email)?_c('div',{staticClass:"invalid-tooltip"},[_vm._v(" Provide valid email ")]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"input-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.password.$model),expression:"$v.details.password.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                      'is-invalid': _vm.$v.details.password.$error,
                      'is-valid': !_vm.$v.details.password.$invalid,
                    },attrs:{"id":"password","spellcheck":"false","autocomplete":"off","trim":"","type":_vm.fieldTypes.password,"required":""},domProps:{"value":(_vm.$v.details.password.$model)},on:{"focus":_vm.handleType,"blur":[_vm.handleType,function($event){return _vm.$forceUpdate()}],"keyup":_vm.errorNull,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.password, "$model", $event.target.value.trim())}}}),_c('span',{staticClass:"placeholder"},[_vm._v("Password")]),_c('i',{attrs:{"id":"togglePassword"},domProps:{"innerHTML":_vm._s(_vm.btnText)},on:{"click":_vm.showPassword}}),(!_vm.$v.details.password.required)?_c('div',{staticClass:"invalid-tooltip"},[_vm._v(" Password is required ")]):_vm._e(),(!_vm.$v.details.password.minLength)?_c('div',{staticClass:"invalid-tooltip"},[_vm._v(" Minimum 8 characters required ")]):_vm._e(),(!_vm.$v.details.password.maxLength)?_c('div',{staticClass:"invalid-tooltip"},[_vm._v(" Maximum 40 characters allowed. ")]):_vm._e()])]),_c('div',{staticClass:"event-btns-wrapper"},[(this.isDisabled == false)?_c('button',{staticClass:"btn btn-primary w-100",attrs:{"type":"button","disabled":_vm.isLoadingArray[0]},on:{"click":function($event){return _vm.Login(0)}}},[(_vm.isLoadingArray[0])?_c('div',{staticClass:"lds-dual-ring"}):_c('span',[_vm._v("Sign In")])]):(this.isDisabled == true)?_c('button',{staticClass:"btn btn-primary-disabled w-100",attrs:{"type":"button","disabled":(true, _vm.isLoadingArray[0])}},[(_vm.isLoadingArray[0])?_c('div',{staticClass:"lds-dual-ring"}):_c('span',[_vm._v("Sign In")])]):_vm._e()])]),_c('div',{staticClass:"link-wrapper"},[_c('a',{staticClass:"forgot-password",on:{"click":_vm.forgotPassword}},[_vm._v("Forgot Password?")])]),_c('button',{staticClass:"auth-link",on:{"click":_vm.register}},[_vm._v(" Signup for an Account ")])]),_c('AuthBottom')],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }