<template>
  <div class="auth-wrapper overflow-hidden">
    <div class="container-fluid no-pdd">
      <div class="row align-items-center no-gutters">
        <div class="col-lg-6 d-none d-lg-block no-pdd">
          <Left />
        </div>
        <div class="col-lg-6 no-pdd">
          <div class="auth-side">
            <AuthTop />
            <div class="sign-in-form">
              <form @submit.prevent @keyup.enter="Login(0)" id="form-wrapper">
                <h4>Welcome Back</h4>
                <p
                  v-if="errorMessage != null"
                  class="tag-line"
                  style="color: red"
                >
                  {{ errorMessage }}
                </p>
                <p v-else class="tag-line">
                  Enter email and password to login.
                </p>
                <div class="form-group">
                  <div class="input-wrapper">
                    <input
                      id="email"
                      :class="{
                        'is-invalid': $v.details.email.$error,
                        'is-valid': !$v.details.email.$invalid,
                      }"
                      v-model.trim="$v.details.email.$model"
                      spellcheck="false"
                      autocomplete="off"
                      trim
                      type="text"
                      class="form-control"
                      required
                      v-on:keyup="errorNull"
                    />

                    <span class="placeholder">Email</span>
                    <div
                      v-if="!$v.details.email.required"
                      class="invalid-tooltip"
                    >
                      Email is required
                    </div>
                    <div v-if="!$v.details.email.email" class="invalid-tooltip">
                      Provide valid email
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="input-wrapper">
                    <input
                      id="password"
                      v-model.trim="$v.details.password.$model"
                      spellcheck="false"
                      autocomplete="off"
                      :class="{
                        'is-invalid': $v.details.password.$error,
                        'is-valid': !$v.details.password.$invalid,
                      }"
                      trim
                      :type="fieldTypes.password"
                      required
                      class="form-control"
                      @focus="handleType"
                      @blur="handleType"
                      v-on:keyup="errorNull"
                    />

                    <span class="placeholder">Password</span>
                    <i
                      v-html="btnText"
                      id="togglePassword"
                      @click="showPassword"
                    ></i>
                    <div
                      v-if="!$v.details.password.required"
                      class="invalid-tooltip"
                    >
                      Password is required
                    </div>
                    <div
                      v-if="!$v.details.password.minLength"
                      class="invalid-tooltip"
                    >
                      Minimum 8 characters required
                    </div>
                    <div
                      v-if="!$v.details.password.maxLength"
                      class="invalid-tooltip"
                    >
                      Maximum 40 characters allowed.
                    </div>
                  </div>
                </div>
                <div class="event-btns-wrapper">
                  <button
                    v-if="this.isDisabled == false"
                    type="button"
                    class="btn btn-primary w-100"
                    @click="Login(0)"
                    :disabled="isLoadingArray[0]"
                  >
                    <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                    <span v-else>Sign In</span>
                  </button>
                  <button
                    v-else-if="this.isDisabled == true"
                    type="button"
                    class="btn btn-primary-disabled w-100"
                    :disabled="(true, isLoadingArray[0])"
                  >
                    <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                    <span v-else>Sign In</span>
                  </button>
                </div>
              </form>
              <div class="link-wrapper">
                <a class="forgot-password" @click="forgotPassword"
                  >Forgot Password?</a
                >
              </div>
              <button @click="register" class="auth-link">
                Signup for an Account
              </button>
            </div>
            <AuthBottom />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  email,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";
import Left from "../../components/AuthLeft/Left.vue";
import AuthTop from "../../components/AuthLeft/AuthTop.vue";
import AuthBottom from "../../components/AuthLeft/AuthBottom.vue";

export default {
  name: "Home",
  components: { Left, AuthTop, AuthBottom },
  data: function() {
    return {
      lastSegment: "",
      isDisabled: true,
      resetIcon: require("../../assets/favicon.png"),
      errorMessage: null,
      isLoadingArray: [],
      fieldTypes: {
        password: "password",
      },
      details: {
        sendLinkUrl: "",
        email: "",
        password: "",
      },
      btnText: '<i class="fa fa-eye-slash"></i>',
    };
  },
  validations: {
    details: {
      email: { required, email },
      password: { required, minLength: minLength(8), maxLength: maxLength(40) },
    },
  },

  computed: {
    ...mapGetters(["errors"]),
  },

  mounted() {
    this.getLinkUrl(1);
    const favicon = document.getElementById("favicon");
    favicon.href = this.resetIcon;
    this.$store.commit("setErrors", {});
    document.getElementById("form-wrapper").click();
    
    // Listen for paste event on the email input field
    const emailInput = document.getElementById("email");
    emailInput.addEventListener("paste", this.handlePaste.bind(this));

    // Listen for paste event on the email input field
    const PasswordInput = document.getElementById("password");
    PasswordInput.addEventListener("paste", this.handlePaste.bind(this));
  },

  methods: {
    ...mapActions("auth", ["sendLoginRequest"]),
    validationStatus: function(validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    handleType(event) {
      const { srcElement, type } = event;
      const { name, value } = srcElement;

      if (type === "blur" && !value) {
        this.fieldTypes[name] = "text";
      } else {
        this.fieldTypes[name] = "password";
      }
    },
    getLinkUrl() {
      var linkUrl = window.location.pathname;
      var segment_array = linkUrl.split("/");
      this.lastSegment = segment_array.pop();
      if (this.lastSegment !== "login") {
        localStorage.setItem("linkUrl", this.lastSegment);
      }
    },

    Login: function(index) {
      this.errorMessage = null;
      this.isDisabled = true;
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      this.$set(this.isLoadingArray, index, true);
      this.details.sendLinkUrl = localStorage.getItem("linkUrl");
      this.sendLoginRequest(this.details).then((response) => {
        if (response.data.statusCode == 200) {
          if (response.data.data.user.email_verification == "1") {
            this.$router.push({ name: "Verify" });
          } else if (response.data.data.user.mob == null) {
            this.$router.push({ name: "RegisterMobile" });
          } else if (response.data.data.user.mobile_verification === "1") {
            this.$router.push({ name: "VerifyMobile" });
          } else {
            if (
              response.data.data.userByCopy == null ||
              (response.data.data.userByCopy.length == 0 &&
                response.data.data.invite_count == 0)
            ) {
              this.$router.push({ name: "Teams" });
            } else {
              this.$router.push({ name: "PendingInvite" });
            }
          }
        } else {
          setTimeout(() => {
            this.$set(this.isLoadingArray, index, false);
            this.errorMessage = response.data.message;
          }, 500);
        }
        localStorage.setItem("addedCart", []);
      });
    },

    register: function() {
      this.$router.push({ name: "Register" });
    },

    forgotPassword: function() {
      this.$router.push({ name: "Forgot" });
    },

    handlePaste() {
      setTimeout(() => {
        this.errorNull();
      }, 0);
    },

    showPassword: function() {
      if (this.fieldTypes.password === "password") {
        this.fieldTypes.password = "text";
        this.btnText = "<i class='fa fa-eye'></i>";
      } else {
        this.fieldTypes.password = "password";
        this.btnText = "<i class='fa fa-eye-slash'></i>";
      }
    },

    errorNull: function() {
      this.errorMessage = null;
      if (this.$v.$invalid) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
  },
};
</script>
